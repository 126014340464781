<template>
	<div>
		<a-spin :spinning="loading">
			<div class="mt20 ft20 ftw500 cl-main">开发中应用</div>
			<div class="mt30">
				
				<div class="flex wrap" v-if="datas.length>0">
					<div class="application-index-item" v-for="(item,index) in datas" :key="index">
						<div class="flex alcenter space" style="width: 100%;">
							<div class="flex alcenter">
								<img v-if="item.logo != null" :src="item.logo" class="application-index-item-logo"/>
								<img v-else src="../../assets/image/woyaoyou_application01@2x.png" class="application-index-item-logo"/>
								<div class="ml20">
									<div class="ft18 ftw500 cl-main text-over4">{{item.name}}</div>
									<div class="ft14 ftw500 cl-theme mt12 text-over4">¥0起 ｜ 免费试用{{item.trial_days}}天</div>
								</div>
							</div>
							
							<div>
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="edit(index)">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">编辑应用</span>
											</a>
										</a-menu-item>
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="pubApp(index)">
												<i class="iconfont ft14 iconshangjia" ></i>
												<span class="ml10">发布应用</span>
											</a>
										</a-menu-item>
										
									</a-menu>
								</a-dropdown>
							</div>
						</div>
						
						<img src="../../assets/image/label_recommend@2x.png" class="application-index-item-tui" v-if="item.is_tui==1"/>
					</div>
				</div>
				
				<div v-else class="mt20 flex center">
					<a-empty/>
				</div>
				
				<div class="flex center mt20">
					<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
				</div>
			</div>
			
			<div v-if="pubAppLoading">
				<pub-app :visible="pubAppLoading" @cancel="cancelPubApp" @ok="okPubApp"></pub-app>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import pubApp from './components/developing/modal/pubApp.vue';
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins:[listMixin],
		components:{
			pubApp,
		},
		data(){
			return{
				loading:false,
				pubAppLoading:false,
				datas:[],
				search:{
					status:0,
				},
				index:0,
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getApplianceList',{
					status:this.search.status,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			edit(index){
				this.$router.push('/application/edit?id='+this.datas[index].app_id);
			},
			
			
			
			pubApp(index){
				this.index = index
				this.pubAppLoading=true;
			},
			cancelPubApp(){
				this.pubAppLoading=false;
			},
			okPubApp(form){
				this.pubAppLoading=false;
				form.app_id = this.datas[this.index].app_id
				console.log(form)
				this.$http.api('platform/admin/fabuAppliance',form).then(res=>{
					
					this.$message.success('发布成功',1,()=>{
						this.back();
					})
				}).catch(res=>{
					console.log(res);
					
				})
			},
		}
	}
</script>

<style>
	
	@media screen and (max-width:1150px){
		.application-index-item{
			width: 99%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	
	@media screen and (min-width:1151px) and (max-width:1400px){
		.application-index-item{
			width: 49%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	
	@media screen and (min-width:1401px){
		.application-index-item{
			width: 32%;
			height: 120px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #EBEDF5;
			padding: 30px;
			position: relative;
			margin-right: 1%;
			margin-bottom: 1%;
		}
	}
	
	.application-index-item-logo{
		width: 60px;
		height: 60px;
		border-radius: 4px;
	}

	.application-index-item-tui{
		position: absolute;
		top:0px;
		left:0px;
		width: 50px;
		height: 50px;
	}
</style>
